import { Pipe, PipeTransform } from "@angular/core";
import { environment } from '../../environments/environment';

@Pipe({
	name: 'iframeTransform'
})
export class IframeTransformPipe implements PipeTransform {
	project_url = environment.project_url;
	device_type = environment.device_type;

	constructor() { }

	getRemainingUrl(url) {
		// Remove protocol
		let remainingUrl = url.replace(/^(https?:|ftp:|)\/\//, '');
		// Remove www
		remainingUrl = remainingUrl.replace(/^www\./, '');
		// Remove domain
		remainingUrl = remainingUrl.replace(/^([a-zA-Z0-9.-]+)\//, '');
		return remainingUrl;
	}

	transform(value: any, ...args: any[]) {
		// const regex = new RegExp(`^${this.project_url}`);
		// if ((this.device_type == 'android' || this.device_type == 'ios') && regex.test(value)) {
		// 	const remainingUrl = this.getRemainingUrl(value);
		// 	return `/${remainingUrl}`;
		// }
		return value;
	}
}