import { Pipe, PipeTransform } from '@angular/core';
import { ReplaceEmojisPipe } from './emoji.pipe';
import { environment } from '../../environments/environment';

@Pipe({
	name: 'clickableText'
})
export class TransformStringPipe implements PipeTransform {
	constructor(
		private emojPipe: ReplaceEmojisPipe,
	) { }

	sheet: any = 'itsgametime';
	size = 24;
	sheetSize = 64;
	sheetId: any = 100;
	project_url = environment.project_url;
	device_type = environment.device_type;

	backgroundImageFn = (set: string, sheetSize: number) => {
		return `${environment.emoji_path}${this.sheet}/${sheetSize}.png`;
	}

	getRemainingUrl(url) {
		// Remove protocol
		let remainingUrl = url.replace(/^(https?:|ftp:)\/\//, '');

		// Remove www (optional)
		remainingUrl = remainingUrl.replace(/^www\./, '');

		// Remove domain
		remainingUrl = remainingUrl.replace(/^([a-zA-Z0-9.-]+)\//, '');

		return remainingUrl;
	}

	transform(fullString: string, showFullText: boolean = false, limit: number = 250): any {
		// Regular expression to match URLs
		const urlRegex = /(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^?\n\r\s]*)?(?:\?[^#\n\r\s]*)?(?:#[^\n\r\s]*)?/g;

		// Regular expression to match hashtags
		// const hashtagRegex = /(?:\s|^)(#\w+)/gm; //includes linebreaks
		const hashtagRegex = / ?#(\w+)/gi;
		// const hashtagRegex = /(^( +)?#(\w+))|( #(\w+))/gi;

		// Regular expression to match iframes
		const iframeRegex = /<iframe[^>]+src=['"]([^'"]+)['"][^>]*><\/iframe>/g;

		// Replace iframes with their src attribute
		fullString = fullString.replace(iframeRegex, (match, src) => {
			return src;
		});

		//remove html tags from string
		fullString = fullString.replace(/<[^>]*>/g, '');

		fullString = fullString.replace(/\n/gm, " \n ");
		fullString = fullString.replace(/\r/gm, "");

		let dataArray = fullString.split('');

		let tempString = '';

		if (showFullText === true) {
			const tempArray = dataArray.map((e) => {
				const tempStringData = this.emojPipe.transform(e, this.sheet, this.size, 64, this.sheetId, true, this.backgroundImageFn, true);
				e = tempStringData['html'];
				tempString += e + '';
			});

			// Replace URLs with clickable links
			var ts = tempString;
			ts = tempString.replace(urlRegex, "<a  href='$&' target='_blank'>$&</a> ");
			// if (this.device_type == 'android' || this.device_type == 'ios') {
			// 	var its: any;
			// 	its = tempString.match(urlRegex);
			// 	if (its && its != null) {
			// 		its.forEach(element => {
			// 			const regex = new RegExp(`^${this.project_url}`);
			// 			if (regex.test(element)) {
			// 				const url = element;
			// 				const remainingUrl = this.getRemainingUrl(url);
			// 				tempString = tempString.replace(element, `<a  href='/${remainingUrl}'>$&</a>`);
			// 				console.log('temp', tempString);

			// 				// var el = (element.split('/')).reverse();
			// 				// tempString = tempString.replace(element, `<a  href='/${el[1]}/${el[0]}'>$&</a>`);
			// 			} else {
			// 				tempString = tempString.replace(element, "<a  href='$&' target='_blank'>$&</a> ");
			// 			}
			// 		});
			// 		ts = tempString;
			// 	}
			// 	else {
			// 		ts = tempString.replace(urlRegex, "<a  href='$&' target='_blank'>$&</a> ");
			// 	}
			// } else {
			// 	ts = tempString.replace(urlRegex, "<a  href='$&' target='_blank'>$&</a> ");
			// }

			// Replace hashtags with clickable links
			ts = ts.replace(hashtagRegex, "<a href='/userSearch/$1'> #$1</a>");

			//replace emoji with custom emoji
			const tempStringData = this.emojPipe.transform(ts, this.sheet, this.size, 64, this.sheetId, true, this.backgroundImageFn, true);

			ts = tempStringData['html'];

			ts = ts.replace(" \n ", "\n");
			ts = ts.replace(/ &nbsp;/g, '');

			return ts;
		}
		else {
			const tempFullString = fullString.slice(0, limit);
			if (!tempFullString.includes('')) {
				dataArray = tempFullString.split('');
			}
			const tempArray = dataArray.map((e) => {
				const tempStringData = this.emojPipe.transform(e, this.sheet, this.size, 64, this.sheetId, true, this.backgroundImageFn, true);
				e = tempStringData['html'];

				if (tempString.length < limit) {
					if (!tempFullString.includes('')) {
						tempString += e + '';
					} else {
						tempString += e + '';
					}
				}
			});

			// Replace URLs with clickable links
			var ts = tempString;
			ts = tempString.replace(urlRegex, "<a  href='$&' target='_blank'>$&</a> ");
			// if (this.device_type == 'android' || this.device_type == 'ios') {
			// 	var its: any;
			// 	its = tempString.match(urlRegex);
			// 	if (its && its != null) {
			// 		its.forEach(element => {
			// 			const regex = new RegExp(`^${this.project_url}`);
			// 			if (regex.test(element)) {
			// 				const url = element;
			// 				const remainingUrl = this.getRemainingUrl(url);
			// 				tempString = tempString.replace(element, `<a  href='/${remainingUrl}'>$&</a>`);
			// 				console.log('temp', tempString);

			// 				// var el = (element.split('/')).reverse();
			// 				// tempString = tempString.replace(element, `<a  href='/${el[1]}/${el[0]}'>$&</a>`);
			// 			} else {
			// 				tempString = tempString.replace(element, "<a  href='$&' target='_blank'>$&</a> ");
			// 			}
			// 		});
			// 		ts = tempString;
			// 	}
			// 	else {
			// 		ts = tempString.replace(urlRegex, "<a  href='$&' target='_blank'>$&</a> ");
			// 	}
			// } else {
			// 	ts = tempString.replace(urlRegex, "<a  href='$&' target='_blank'>$&</a> ");
			// }

			// Replace hashtags with clickable links
			ts = ts.replace(hashtagRegex, "<a href='/userSearch/$1'> #$1</a>");
			// ts = ts.replace(hashtagRegex, function (match, group1, group2, group3, group4, group5) {
			// 	// Determine which group matched
			// 	let matchedGroup = group1 || group2 || group3 || group4 || group5;

			// 	// Create the replacement string dynamically
			// 	return "<a href='/userSearch/" + matchedGroup + "'>" + matchedGroup + "</a>";
			// });

			//replace emoji with custom emoji
			const tempStringData = this.emojPipe.transform(ts, this.sheet, this.size, 64, this.sheetId, true, this.backgroundImageFn, true);

			ts = tempStringData['html'];

			ts = ts.replace(" \n ", "\n");
			ts = ts.replace(/ &nbsp;/g, ' ');

			const data = (fullString.length > limit ? ts + '<span> ...</span>' : ts);

			return data;
		}
	}
}
