import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthPipe'
})
export class MonthPipe implements PipeTransform {
  transform(value: number | string, type: 'full' | 'short' = 'short'): string {
    const monthIndex = typeof value === 'string' ? parseInt(value, 10) : value;
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    if (monthIndex >= 0 && monthIndex <= 11) {
      if (type === 'short') {
        return months[monthIndex].substring(0, 3);
      } else {
        return months[monthIndex];
      }
    } else {
      return '';
    }
  }
}
